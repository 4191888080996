import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PageviewIcon from '@material-ui/icons/Pageview';
import Button from '@material-ui/core/Button';
import Topbar from './components/Topbar';
import BarChartIcon from '@material-ui/icons/BarChart';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LanguageIcon from '@material-ui/icons/Language';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';

import QuizResult from './components/QuizResult';

const numeral = require('numeral');
numeral.defaultFormat('0,000');

const backgroundShape = require('../images/shape.svg');

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  loadingState: {
    opacity: 0.05
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152,
    height: 36
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing(2),
  },
  loanAvatar: {
    display: 'inline-block',
    verticalAlign: 'center',
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  interestAvatar: {
    display: 'inline-block',
    verticalAlign: 'center',
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  mainBadge: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
});

class AdminPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      rows: [],
      newGroup: false,
      newGroupName: "",
      newGroupId: 0,
      showNewGroupURL: false,
      avgData: null
    };
    this.updateTable = this.updateTable.bind(this);
    this.updateTable(props.match.params.token);
  }

  updateTable(token){
    const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;
    fetch(`${baseURL}/api/group/list`, {
      method: "get",
      credentials: 'include',
      headers:new Headers({Authorization: "Bearer " + token })
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          rows: data
        });
      })
      .catch(error => console.log("error", error));
  }

  render() {
    //console.log("token", this.props.match.params.token);
    const { classes, match } = this.props;
    const { rows, newGroup, newGroupName, newGroupId, showNewGroupURL, avgData } = this.state;
    const currentPath = this.props.location.pathname
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container justify="center" >
            <Grid alignItems="center" justify="center" container className={classes.grid}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <div className={classes.topBar}>
                    <div className={classes.block}>
                      <Typography variant="h6" gutterBottom>Grupos</Typography>
                      <Typography variant="body1">
                        Aqui puedes ver los grupos existentes o crear uno nuevo.
                      </Typography>
                    </div>
                    <div>
                      <Button variant="contained" color="primary" className={classes.outlinedButtom} onClick={()=>{
                        this.setState({newGroup: true});
                      }}>
                        <AddCircleIcon /> &nbsp;&nbsp;Crear nuevo grupo
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <div>
                      <div className={classes.box}>
                        <Typography color='secondary' gutterBottom>
                          Listado de grupos
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Grupo</TableCell>
                                <TableCell align="right">Nro. Tests</TableCell>
                                {/*<TableCell align="right">Promedio</TableCell>*/}
                                <TableCell align="right">Ver Resumen</TableCell>
                                <TableCell align="right">Ver Tests</TableCell>
                                <TableCell align="right">Ver URL</TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, index) => (
                                <TableRow key={row.id}>
                                  <TableCell component="th" scope="row">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="right">{row.count}</TableCell>
                                  {/*<TableCell align="right">{row.averageScore}</TableCell>*/}
                                  <TableCell align="right"><a href="#!" onClick={()=>{
                                    const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;
                                    fetch(`${baseURL}/api/quiz/list/${row.id}`, {
                                      method: "get",
                                      credentials: 'include',
                                      headers:new Headers({Authorization: "Bearer " + match.params.token })
                                    })
                                      .then(response => response.json())
                                      .then(data => {
                                        const finalData = [];
                                        if (data.length>0){
                                          for (let i=0; i<data[0].answers.split(",").length; i++){
                                            let avg = 0;
                                            for (let j=0; j<data.length; j++){
                                              avg += parseInt(data[j].answers.split(",")[i], 10);
                                            }
                                            finalData.push(avg/data.length);
                                          }
                                          this.setState({avgData: finalData});
                                        }else{
                                          //no data
                                        }
                                      })
                                      .catch(error => console.log("error", error));
                                  }}><BarChartIcon /></a></TableCell>
                                  <TableCell align="right"><a href={`/responses/${encodeURIComponent(row.id)}/${encodeURIComponent(match.params.token)}`}><PageviewIcon /></a></TableCell>
                                  <TableCell align="right"><a href="#!" onClick={()=>this.setState({showNewGroupURL: true, newGroupId: row.id})}><LanguageIcon /></a></TableCell>
                                  <TableCell align="right"><a href="#!" onClick={()=>{
                                    const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;
                                    fetch(`${baseURL}/api/group/delete/${row.id}`, {
                                      method: "get",
                                      credentials: 'include',
                                      headers:new Headers({Authorization: "Bearer " + match.params.token })
                                    })
                                      .then(response => response.json())
                                      .then(() => {
                                        this.updateTable(match.params.token);
                                      })
                                      .catch(error => console.log("error", error));
                                  }}><DeleteIcon /></a></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Dialog open={newGroup} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Nuevo Grupo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa un nombre para identificar a tu grupo. (Puede ser repetido).
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre de grupo"
              onChange={e=>this.setState({newGroupName: e.target.value})}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{this.setState({newGroup: false, newGroupName : ""})}} color="primary"> Cancelar </Button>
            <Button onClick={()=>{
              //fetch POST
              const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;
              fetch(`${baseURL}/api/group/save`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: "Bearer " + match.params.token 
                },
                body: JSON.stringify({
                    "name": newGroupName
                })
              })
                .then(response => response.json())
                .then(data => {
                  console.log(data.id);
                  this.setState({
                    newGroupId: data.id,
                    newGroupName: "",
                    newGroup: false,
                    showNewGroupURL: true
                  });
                  this.updateTable(match.params.token);
                })
                .catch(error => console.log("error", error));
            }} color="primary"> Crear Grupo </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showNewGroupURL} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">URL del grupo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              La URL para completar tests es: <a rel="noopener noreferrer" target="_blank" href={`${window.location.protocol}//${window.location.hostname}/group/${newGroupId}`}>
                {`${window.location.protocol}//${window.location.hostname}/group/${newGroupId}`}
              </a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{this.setState({showNewGroupURL: false})}} color="primary"> Cerrar </Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={avgData !== null}
          onClose={()=>{
            this.setState({avgData: null});
          }}
          onRendered={()=>{
            const quizResultModal = document.getElementById('quizResultModal');
            if (quizResultModal){
              quizResultModal.scrollIntoView({behavior: "smooth", block: "center"});
            }
          }}
          style={{
            width: "560px", 
            position: "absolute",
            top: "5%",
            left: "10%"
          }}>
          <QuizResult 
            firstName={""}
            lastName={""}
            motherMaidenName={""}
            email={""}
            answers={avgData !== null ? avgData : [] } />
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(AdminPage));