import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: {
            app_name: "HOLISTIC INNOVATION COEFFICIENT",
            phoneNumber: "Whatsapp Number",
            name: "Name",
            lastname: "Last Name",
            mothermaidenname: "Mother's Maiden Name (optional)",
            email: "Email",
            anonymous: "Anonymous",
            completeQuestionnaire: "Complete Questionnaire",
            process: "Process",
            startAgain: "Start again",
            questionnaireResult: "Questionnaire result",
            coihResult: "Your HOIC is <b>{{quizResponse.value}}</b>, is classified as <b>{{quizResponse.category}}</b>.",
            avgResults: "Average results per area:",
            averageValue: "Average value",
            LOW: 'LOW',
            MEDIUM: 'MEDIUM',
            HIGH: 'HIGH',
            VERY_HIGH: 'VERY HIGH',
            g01: 'STRESS MANAGEMENT',
            g02: 'BELIEF TO BE CREATIVE',
            g03: 'SELF-KNOWLEDGE',
            g04: 'INTROSPECTION',
            g05: 'LOGICAL THINKING + LATERAL THINKING',
            g06: 'INTUITION + REASON',
            g07: 'HIGH AUTOMOTIVATION',
            g08: 'EASE OF FORMULATING PROBLEMS',
            g09: 'CONSTANT SEARCH FOR IDEAS',
            g10: 'TRANSGRESSIVE ATTITUDE',
            g11: 'ADVENTURE ATTITUDE',
            g12: 'CREATIVE LEADERSHIP',
            g13: 'KNOW HOW TO THINK IN AN NAIVE WAY',
            g14: 'SEARCH FOR RECOGNITION',
            g15: 'SEARCH FOR LIFE IMPROVEMENT',
            g16: 'HOLISTIC INNOVATION LEVEL',
            q01: 'I love influencing others and getting them to enjoy ideas and projects like me.',
            q02: 'I feel that it limits my ability to create when I am being pushed for results at a certain time when I don\'t know what I should do.',
            q03: 'I feel that my ability to create is stimulated when I am being pushed for results at a certain time, but I know what I should do.',
            q04: 'I feel that my ability to create is limited when I am being asked not to be wrong.',
            q05: 'I realize I\'m a bit more creative than the people around me.',
            q06: 'I\'m a creative person, but sometimes I block myself.',
            q07: 'At school, at home, or now at work, I\'ve never been considered a creative person.',
            q08: 'I personally don\'t consider myself a creative person because I think I am blocked.',
            q09: 'I often have conversations with myself.',
            q10: 'I am aware of my strengths and weaknesses.',
            q11: 'I think I know myself well.',
            q12: 'I am aware of how far I can go; I know well my skills and limitations at a professional and personal level.',
            q13: 'To unlock my mind, I listen to music as a means of inspiration.',
            q14: 'To unlock my mind, I read inspiring books.',
            q15: 'To unlock my mind, I use painting as a medium of inspiration.',
            q16: 'To unlock my mind, I travel constantly.',
            q17: 'I consider myself a good leader.',
            q18: 'I remember my dreams well, sometimes they become a certain source of inspiration.',
            q19: 'The pressure on personal projects, study, work, absorb me in the day to day, and move me away from inspiration.',
            q20: 'I can\'t transform an absurd idea into something that makes sense when I don\'t have a defined scheme.',
            q21: 'I can transform an absurd idea into something that makes sense when I don\'t have a defined scheme.',
            q22: 'The pressure to meet goals in some projects I\'m involved in blocks my lateral thinking to resolve things.',
            q23: 'The pressure to meet the goals in some projects I\'m involved in encourages my lateral thinking to resolve things.',
            q24: 'The pressure to meet goals in some projects has blocked my intuition.',
            q25: 'Sometimes or often, after thinking things over carefully, the solution or the answer to what I was suddenly looking for comes up.',
            q26: 'I love having a lot of alternative ideas, I don\'t usually settle for the first thing that comes to mind.',
            q27: 'Under pressure, I like to be provocative in my approaches, although in the end it always ends up imposing logic.',
            q28: 'I enjoy my work; I feel a special motivation for everything I do.',
            q29: 'I always have a lot of energy and dynamism. I\'m always involved in projects that excite me.',
            q30: 'I have sometimes felt that fear of not achieving my goals could wear me out more than enough.',
            q31: 'I can easily detect problems in my professional environment.',
            q32: 'I can easily express my ideas graphically, through drawings, schemas, or maps.',
            q33: 'I have the ease to clearly define a goal and start working on it.',
            q34: 'I love to tear things apart, see how they are inside, analyze them, figure out how they work.',
            q35: 'Sometimes my ease of formulating problems and identifying creative pockets is blocked by pressure to achieve goals in a short time and without any scheme.',
            q36: 'I like to know things about different topics, beyond my everyday work.',
            q37: 'I know how brainstorming works, and I think I could lead it perfectly.',
            q38: 'Sometimes the fear of being wrong makes it difficult to find ideas.',
            q39: 'I like to think beyond the established, break the rules.',
            q40: 'I always try to go down the less busy roads, I don\'t like doing everything almost everybody does.',
            q41: 'I like to experience new things, even though I don\'t know if I\'m on the right track or not.',
            q42: 'When I generate ideas for something, I don\'t care if crazy, unrealizable things appear.',
            q43: 'I\'m used to do things I\'ve never done before.',
            q44: 'Sometimes pressure encourages me to overcome my goals and break the status quo.',
            q45: 'Sometimes pressure blocks my creativity and keeps me in the same place.',
            q46: 'I like taking risks.',
            q47: 'I love traveling and observing customs and ways of living different from mine.',
            q48: 'I\'m attracted to strange, inaccessible, hidden, ambiguous things.',
            q49: 'I often feel that the solution to a problem is going through me, emotionally, and that because of that feeling I can make decisions.',
            q50: 'The adrenaline that generates the unknown drives me to start new projects.',
            q51: 'The pressure of the current projects I find myself in limits me to thinking about new projects.',
            q52: 'I like to implement things and I know systems and working methodologies to turn abstract ideas into concrete facts.',
            q53: 'I have personally (or as a collaborator in) carried out some concrete innovation (product, service, problem) with some success.',
            q54: 'I use occasionally, or often, creative techniques to generate ideas on an issue of my interest.',
            q55: 'When I have a clear idea, it is easy for me to convince others, to convey that conviction to them, and to drag them into action.',
            q56: 'I can turn my problems into phrases or formulas that allow more efficiency and precision.',
            q57: 'I love to participate in a team and jointly seek ideas for any topic of common interest.',
            q58: 'I\'ve read some books about techniques for creativity.',
            q59: 'Sometimes putting pressure on my team has worked well for me.',
            q60: 'Sometimes putting pressure on my team has blocked my creativity.',
            q61: 'I delight in turning things around.',
            q62: 'I get along well with children, I like to share their games and their way of seeing life.',
            q63: 'The need to want to meet the deadlines erodes my curiosity.',
            q64: 'When something doesn\'t work out well, I\'m able to recover emotionally in a very short time.',
            q65: 'Sometimes fears of me being wrong or not achieving the results prevent me from starting a project.',
            q66: 'The success of innovating in different products, processes, and services for my own benefit and/or that of others fills me with satisfaction.',
            q67: 'I\'m a practical person, who enjoys seeing concrete results.',
            q68: 'The biggest stimulus I\'ve had in every creative process has been to commit to the purpose of each project.',
            q69: 'I am not encouraged by projects that do not represent a life improvement.',
            q70: 'I like to solve problems and/or generate disruptive ideas by inspiring me with stimuli that have nothing to do with what I\'m doing.',
            q71: 'I like to solve problems and/or generate moderately new ideas in their environment by inspiring me on stimuli that have nothing to do with what I am doing.',
            q72: 'I like to solve problems and/or generate ideas with a certain level of innovation that has been adopted from other realities by inspiring me on stimuli that have nothing to do with what I am doing.'
        },
    },
    es: {
        translation: {
            app_name: "COEFICIENTE DE INNOVACIÓN HOLÍSTICA",
            phoneNumber: "Número de Whatsapp",
            name: "Nombres",
            lastname: "Apellido Paterno",
            mothermaidenname: "Apellido Materno (opcional)",
            email: "Email",
            anonymous: "Anónimo",
            startAgain: "Empezar nuevamente",
            completeQuestionnaire: "Completar Cuestionario",
            process: "Procesar",
            questionnaireResult: "Resultado del cuestionario",
            coihResult: "Tu COIH es de <b>{{quizResponse.value}}</b>, está clasificado como <b>{{quizResponse.category}}</b>.",
            avgResults: "Resultados promedio obtenidos en cada área:",
            averageValue: "Valor promedio",
            LOW: 'BAJO',
            MEDIUM: 'MEDIO',
            HIGH: 'ALTO',
            VERY_HIGH: 'MUY ALTO',
            g01: 'GESTIÓN DEL ESTRÉS',
            g02: 'CREENCIA DE SER CREATIVO',
            g03: 'AUTOCONOCIMIENTO',
            g04: 'INTROSPECCIÓN',
            g05: 'PENSAMIENTO LÓGICO + PENSAMIENTO LATERAL',
            g06: 'INTUICIÓN + RAZÓN',
            g07: 'AUTOMOTIVACIÓN ELEVADA',
            g08: 'FACILIDAD PARA FORMULAR PROBLEMAS',
            g09: 'BÚSQUEDA CONSTANTE DE IDEAS',
            g10: 'ACTITUD TRANSGRESORA',
            g11: 'ACTITUD AVENTURERA',
            g12: 'LIDERAZGO CREATIVO',
            g13: 'SABER PENSAR DE FORMA INGENUA',
            g14: 'BÚSQUEDA DE RECONOCIMIENTO',
            g15: 'BÚSQUEDA DE MEJORA DE VIDA',
            g16: 'NIVEL DE INNOVACIÓN HOLÍSTICA',
            q01: 'Me encanta influenciar en los demás y conseguir que disfruten como yo las ideas y los proyectos.',
            q02: 'Siento que se limita mi capacidad de crear cuando me presionan por los resultados en un tiempo determinado cuando no conozco lo que debo hacer.',
            q03: 'Siento que se estimula mi capacidad de crear cuando me presionan por los resultados en un tiempo determinado, pero conozco lo que debo hacer. ',
            q04: 'Siento que se limita mi capacidad de crear cuando me exigen que no me equivoque.',
            q05: 'Me doy cuenta de que soy algo más creativo (a) que la gente que me rodea.',
            q06: 'Soy una persona creativa, pero a veces me bloqueo.',
            q07: 'En la escuela, en casa o ahora en el trabajo nunca he sido considerado (a) una persona creativa.',
            q08: 'En lo personal no me considero una persona creativa porque considero que estoy bloqueado.',
            q09: 'A menudo tengo conversaciones conmigo mismo (a).',
            q10: 'Soy consciente de mis puntos fuertes y de mis puntos débiles.',
            q11: 'Creo que me conozco bien a mí mismo(a).',
            q12: 'Soy consciente de hasta dónde puedo llegar, conozco bien mis capacidades y mis limitaciones a nivel profesional y personal.',
            q13: 'Para desbloquear mi mente escucho música como medio de inspiración.',
            q14: 'Para desbloquear mi mente leo libros inspiradores.',
            q15: 'Para desbloquear mi mente utilizo la pintura como medio de inspiración.',
            q16: 'Para desbloquear mi mente viajo constantemente.',
            q17: 'Me considero un buen líder.',
            q18: 'Recuerdo bien mis sueños, a veces se convierten en una certera fuente de inspiración.',
            q19: 'La presión en los proyectos personales, estudio, trabajo, me absorben en el día a día  y me alejan de la inspiración.',
            q20: 'No soy capaz de transformar una idea absurda en algo que tenga sentido cuando no tengo un esquema definido.',
            q21: 'Soy capaz de transformar una idea absurda en algo que tenga sentido cuando no tengo un esquema definido.',
            q22: 'La presión por cumplir las metas en algún proyecto en el que esté involucrado bloquea mi pensamiento lateral   para resolver las cosas.',
            q23: 'La presión por cumplir las metas en algún proyecto en el que esté involucrado incentiva mi pensamiento lateral   para resolver las cosas.',
            q24: 'La presión por cumplir las metas en algún proyecto ha bloqueado mi intuición.',
            q25: 'A veces o a menudo, después de pensar las cosas detenidamente, surge la solución o la respuesta a lo que estaba buscando de forma repentina.',
            q26: 'Me encanta tener muchas ideas alternativas, no suelo conformarme con lo primero que me viene a la cabeza.',
            q27: 'Bajo presión me gusta ser provocativo (a) en mis planteamientos, aunque al final siempre se acabe imponiendo la lógica.',
            q28: 'Disfruto con mi trabajo, siento una especial motivación por todo lo que hago.',
            q29: 'Tengo siempre mucha energía y dinamismo. Estoy siempre metido(a) en proyectos que me ilusionan.',
            q30: 'En algunas ocasiones he sentido que el temor a no lograr mis metas podría desgastarme más de la cuenta.',
            q31: 'Tengo facilidad para detectar los problemas de mi entorno profesional.',
            q32: 'Tengo facilidad para expresar mis ideas de forma gráfica, a través de dibujos, esquemas o mapas.',
            q33: 'Tengo facilidad para definir claramente un objetivo y empezar a trabajar en él.',
            q34: 'Me encanta desmenuzar las cosas, ver cómo son por dentro, analizarlas, descubrir cómo funcionan.',
            q35: 'En algunas ocasiones mi facilidad para formular problemas y concretar focos creativos se ve bloqueada por la presión a lograr metas en corto tiempo y sin ningún esquema.',
            q36: 'Me gusta saber cosas sobre temas diversos, más allá de mi trabajo cotidiano.',
            q37: 'Sé cómo funciona el brainstorming y creo que podría dirigirlo perfectamente.',
            q38: 'En algunas ocasiones el temor a equivocarme dificulta la búsqueda constante de ideas.',
            q39: 'Me gusta pensar más allá de lo establecido, romper las normas.',
            q40: 'Intento siempre ir por los caminos menos transitados, no me gusta hacer todo lo que el mundo hace.',
            q41: 'Me gusta experimentar cosas nuevas, aún sin saber si estoy en el buen camino o no ',
            q42: 'Cuando genero ideas para algo, no me importan si aparecen cosas locas, irrealizables ',
            q43: 'Acostumbro a hacer cosas que no he hecho jamás anteriormente.',
            q44: 'En algunas ocasiones la presión me incentiva a superar mis metas y a romper el statu quo.',
            q45: 'En algunas ocasiones la presión bloquea mi creatividad y  me mantiene en el mismo lugar.',
            q46: 'Me gusta correr riesgos.',
            q47: 'Me encanta viajar y observar costumbres y formas de vivir diferentes a las mías.',
            q48: 'Me atraen las cosas extrañas, poco accesibles, ocultas, ambiguas.',
            q49: 'Muchas veces siento que la solución a un problema pasa por mi interior, de forma emocional, y que gracias a esa sensación soy capaz de tomar decisiones.',
            q50: 'La adrenalina que me genera lo desconocido me impulsa a iniciar nuevos proyectos.',
            q51: 'La presión de los actuales proyectos en los que me encuentro me limita a pensar en proyectos nuevos.',
            q52: 'Me gusta implementar las cosas y conozco sistemas y metodologías de trabajo para convertir ideas abstractas en hechos concretos.',
            q53: 'He llevado a cabo personalmente (o como colaborador en) alguna innovación concreta (producto, servicio, problema) con un cierto éxito.',
            q54: 'Utilizo de vez en cuando, o a menudo, técnicas creativas para generar ideas sobre un tema de mi interés.',
            q55: 'Cuando tengo una idea clara, me resulta fácil convencer a los demás, transmitirles esa convicción y arrastrarlos a la acción.',
            q56: 'Tengo facilidad para convertir mis problemas en frases o fórmulas que permitan con mayor eficacia y concreción.',
            q57: 'Me encanta participar en equipo de trabajo y buscar conjuntamente ideas para cualquier tema de interés común.',
            q58: 'He leído algún libro sobre técnicas de creatividad.',
            q59: 'En algunas ocasiones el presionar a mi equipo me ha dado buenos resultados.',
            q60: 'En algunas ocasiones el presionar a mi equipo me ha bloqueado mi creatividad.',
            q61: 'Me deleito dándole vuelta a las cosas.',
            q62: 'Me llevo bien con los niños, me gusta compartir sus juegos y su forma de ver la vida.',
            q63: 'La necesidad de querer cumplir con los tiempos desgasta mi curiosidad.',
            q64: 'Cuando algo no sale bien soy capaz de recuperarme anímicamente en muy poco tiempo.',
            q65: 'En algunas ocasiones el temor a equivocarme o a no lograr los resultados impide que inicie un proyecto.',
            q66: 'El lograr innovar en diferentes productos, procesos y servicios en mi propio beneficio y/o el de los demás me llena de satisfacción.',
            q67: 'Soy una persona práctica, que disfruta viendo resultados concretos.',
            q68: 'El estímulo más grande que he tenido en cada proceso creativo ha sido comprometerme con el   propósito de cada proyecto.',
            q69: 'No me estimulan los proyectos que no representan una mejora de vida.',
            q70: 'Me gusta resolver problemas y/o generar ideas disruptivas inspirándome en estímulos que no tienen nada que ver con lo que estoy haciendo.',
            q71: 'Me gusta resolver problemas y/o generar ideas medianamente nuevas en su entorno inspirándome en estímulos que no tienen nada que ver medianamente con lo que estoy haciendo.',
            q72: 'Me gusta resolver problemas y/o generar ideas con cierto nivel de innovación y que han sido adoptadas de otras realidades inspirándome en estímulos que no tienen nada que ver medianamente con lo que estoy haciendo.'
        },
    },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;