import Question from './Question';

export default class Quiz {
    constructor(id, name){
        this.id = id;
        this.name = name;
        this.questions = [];
        this.questionGroups = [];
    }

    addQuestion(id, questionGroup, index, value, answers = []){
        const question = new Question(id, this, questionGroup, index, value);
        for (let i = 0; i < answers.length; i++){
            question.addAnswer(answers[i].id, answers[i].index, answers[i].value);
        }
        if (this.questionGroups.findIndex(qGroup => qGroup.id === questionGroup.id) === -1){
            this.questionGroups.push(questionGroup);
        }
        questionGroup.questions.push(question);
        this.questions.push(question);
    }

    shuffleQuestions() {
        var j, x, i;
        for (i = this.questions.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = this.questions[i];
            this.questions[i] = this.questions[j];
            this.questions[j] = x;
        }
    }

}