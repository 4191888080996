import React, {useState} from 'react';

import { useTranslation } from "react-i18next";

import LockOpenIcon from '@material-ui/icons/LockOpen';
import eip from '../images/eip.png';

function LoginPage (props){
  const { t } = useTranslation();
  const [inputData, setInputData] = useState({
    firstName: '', 
    lastName: '', 
    motherMaidenName: '', 
    email: '', 
    phoneNumber: '',
    groupId: props.match.params.group ? props.match.params.group : 1
  });
  
  const handleChange = (field, value)=>{
    const temp = {...inputData};
    temp[field] = value;
    if (field==="anonymous" && value === true){
      temp["firstName"] = "";
      temp["lastName"] = "";
      temp["motherMaidenName"] = "";
      temp["email"] = "";
      temp["phoneNumber"] = "";
    }
    setInputData(temp)
  };

  const handleSubmit = event=>{
    let {firstName, lastName, motherMaidenName, email, groupId, phoneNumber} = inputData;
    const {anonymous} = inputData;
    const {history} = props;

    if (anonymous === true){
      firstName = " ";
      lastName = " ";
      motherMaidenName = " ";
      email = " ";
      phoneNumber = " ";
    } else if (!motherMaidenName || motherMaidenName === ""){
      motherMaidenName = " ";
    }

    const url = 
      `/quiz?`+
      `groupId=${encodeURIComponent(groupId)}&`+
      `firstName=${encodeURIComponent(firstName)}&`+
      `lastName=${encodeURIComponent(lastName)}&` + 
      `motherMaidenName=${encodeURIComponent(motherMaidenName)}&` + 
      `email=${encodeURIComponent(email)}&` + 
      `phoneNumber=${encodeURIComponent(phoneNumber)}`;

    history.push(url);
    event.preventDefault();
  }

  const {firstName, lastName, motherMaidenName, email, anonymous, phoneNumber} = inputData;

  return (
      <div className="limiter">
		    <div className="container-login100">
          <a style={{"position": "absolute", "top": "0px", right: "0px"}} href="/login">
            <LockOpenIcon style={{"position": "relative"}} />
          </a>
			    <div className="wrap-login100 p-b-160 p-t-50">
				    <form className="login100-form validate-form" onSubmit={handleSubmit}>
              <div className="rs1 rs2 pt-3">
                <img src={eip} style={{width: '100%'}} alt="logo" />
              </div>
					    <span className="login100-form-title p-b-43 bgblack">
						    {t('app_name')}
					    </span>
					    <div className="wrap-input100full">
						    <input className={`input100 ${firstName!==''?'has-val':''}`} value={firstName} onChange={(evt)=>{handleChange("firstName", evt.target.value)}} type="text" disabled={anonymous===true ? true : undefined} required={anonymous===true ? undefined : true} />
						    <span className="label-input100">{t('name')}</span>
					    </div>
					    <div className="wrap-input100">
                <input className={`input100 ${lastName!==''?'has-val':''}`} value={lastName} onChange={(evt)=>{handleChange("lastName", evt.target.value)}} type="text" disabled={anonymous===true ? true : undefined} required={anonymous===true ? undefined : true} />
						    <span className="label-input100">{t('lastname')}</span>
					    </div>
              <div className="wrap-input100">
                <input className={`input100 ${motherMaidenName!==''?'has-val':''}`} value={motherMaidenName} onChange={(evt)=>{handleChange("motherMaidenName", evt.target.value)}} type="text" disabled={anonymous===true ? true : undefined} />
						    <span className="label-input100">{t('mothermaidenname')}</span>
					    </div>
              <div className="wrap-input100full">
						    <input className={`input100 ${email!==''?'has-val':''}`} value={email} onChange={(evt)=>{handleChange("email", evt.target.value)}} type={anonymous===true ? "text" : "email"} disabled={anonymous===true ? true : undefined} required={anonymous===true ? undefined : true} />
						    <span className="label-input100">{t('email')}</span>
					    </div>
              <div className="wrap-input100full">
						    <input className={`input100 ${phoneNumber!==''?'has-val':''}`} value={phoneNumber} onChange={(evt)=>{handleChange("phoneNumber", evt.target.value)}} type="text" disabled={anonymous===true ? true : undefined} pattern='[\+]\d{8,15}' title='+9999999999' required={anonymous===true ? undefined : true} />
						    <span className="label-input100">{t('phoneNumber')}</span>
					    </div>
              {/*<div className="wrap-input100full">
						    <input className={`checkbox100`} onChange={(evt)=>{handleChange("anonymous", evt.target.checked)}} type="checkbox" />
                <span className={`checkbox100`}>{t('anonymous')}</span>
              </div>*/}
					    <div className="container-login100-form-btn">
						    <button className="login100-form-btn">
							    {t('completeQuestionnaire')}
						    </button>
					    </div>
				    </form>
			    </div>
		    </div>
	    </div>
    );
}

export default LoginPage;