import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import qs from 'querystring';

import QuizInteractor from '../domain/usecases/QuizInteractor';

function QuizPage(props) {
    const { t } = useTranslation();
    const params = qs.parse(props.location.search.substring(1));
    const [data, setData] = useState({
        groupId: params.groupId ? params.groupId : 1,
        firstName: params.firstName,
        lastName: params.lastName,
        motherMaidenName: params.motherMaidenName,
        email: params.email,
        phoneNumber: params.phoneNumber,
        quiz: {questions:[]},
        answers: [],
        error: ""
    });

    useEffect(()=>{
        const quizInteractor = new QuizInteractor();
        const quiz = quizInteractor.getAvailableQuizzes()[0];
        quiz.shuffleQuestions();
        setData({...data, quiz});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (questionId, value)=>{
        const temp = {...data};
        const {answers} = temp;
        let found = false;
        for (let i=0; i<answers.length; i++){
            if (answers[i].questionId === questionId){
                found = true;
                answers[i].value = value;
            }
        }
        if (!found){
            answers.push({questionId, value});
        }
        setData(temp);
    };
    
    const handleSubmit = (event)=>{
        const {groupId, firstName, lastName, motherMaidenName, email, answers, quiz, phoneNumber} = data;
        const {history} = props;

        if (answers.length !== quiz.questions.length){
            const temp = {...data};
            temp.error = "Necesitas responder todas las preguntas para continuar.";
            setData(temp);
        }else{
            //order questions & answers
            for (let i=0; i<quiz.questions.length; i++){
                for (let j=i+1; j<quiz.questions.length; j++){
                    if (quiz.questions[i].id > quiz.questions[j].id){
                        const tmpQuestion = quiz.questions[i];
                        quiz.questions[i] = quiz.questions[j];
                        quiz.questions[j] = tmpQuestion;
                    }
                }
            }
            let finalAnswers="";
            for (let i=0; i<quiz.questions.length; i++){
                for (let j=0; j<answers.length; j++){
                    if (quiz.questions[i].id === answers[j].questionId){
                        finalAnswers += (finalAnswers.length > 0 ? ",": "") + answers[j].value;
                    }
                }
            }
            //fetch POST
            const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;
            fetch(`${baseURL}/api/quiz/save`, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "quizresponsegroup_id": parseInt(""+groupId, 10),
	                "quiz_id": 1,
	                "firstName": firstName,
	                "lastName": lastName,
	                "motherMaidenName": motherMaidenName,	
	                "email": email,
                    "phoneNumber": phoneNumber,
                    "answers": finalAnswers
                })
            })  
                .then(response => response.json())
                .then(data => {
                    const url = `/result?`+
                    `firstName=${encodeURIComponent(firstName)}&`+
                    `lastName=${encodeURIComponent(lastName)}&` + 
                    `motherMaidenName=${encodeURIComponent(motherMaidenName)}&` + 
                    `email=${encodeURIComponent(email)}&`+
                    `phoneNumber=${encodeURIComponent(phoneNumber)}&`+
                    `answers=${encodeURIComponent(finalAnswers)}`;
                    history.push(url);
                })
                .catch(error => console.log("error", error));
        }
        event.preventDefault();
    };

    const { quiz, error} = data;
    return (
        <div className="limiter">
	        <div className="container-login100">
			    <div className="wrap-login100 p-b-160 p-t-50">
                    <form onSubmit={handleSubmit}>
                        <div className="p-4 bg-white rs1 rs2">
                            <h3 className="my-3">{t('completeQuestionnaire')}</h3>
                            {quiz.questions.map((question,index)=>(
                                <div key={question.id} className="my-3">
                                    <div className="d-flex">{index+1}.&nbsp;{t(question.value)}</div>
                                    <div className="d-flex">
                                        {question.answers.map(answer=>(
                                            <div key={answer.id} className="px-3">
                                                <input type="radio" name={question.id} onChange={evt=>{handleChange(question.id, answer.value)}} />&nbsp;{answer.value}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                            {error && error.trim().length>0 && (<div className="alert alert-danger" role="alert">{error}</div>)}
                        </div>
                        <div className="container-login100-form-btn">
						    <button className="login100-form-btn">
							    {t('process')}
						    </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default QuizPage;