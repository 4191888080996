import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class AdminLoginPage extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(field, value){
    const temp = {};
    temp[field] = value;
    this.setState(temp);
  }

  handleSubmit(event){
    const {username, password} = this.state;
    const {history} = this.props;
    const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;
    fetch(`${baseURL}/api/user/login/${encodeURIComponent(username)}/${encodeURIComponent(password)}`, {
      method: 'get',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        const url = `/admin/${encodeURIComponent(data.access_token)}`;
        console.log("go to:", url);
        history.push(url);
      })
      .catch(error => console.log("error", error));
    
    event.preventDefault();
  }

  render() {
    const {username, password} = this.state;
    return (
      <div className="limiter">
		    <div className="container-login100">
          <a style={{"position": "absolute", "top": "0px", right: "0px"}} href="/">
            <ArrowBackIcon style={{"position": "relative"}} />
          </a>
			    <div className="wrap-login100 p-b-160 p-t-50">
				    <form className="login100-form validate-form" onSubmit={this.handleSubmit}>
					    <span className="login100-form-title p-b-43" style={{"marginBottom": "10px"}}>
						    Login
					    </span>
					    <div className="wrap-input100full rs1 rs2">
						    <input className={`input100 ${username!==''?'has-val':''}`} value={username} onChange={(evt)=>{this.handleChange("username", evt.target.value)}} type="text" />
						    <span className="label-input100">Nombre de Usuario</span>
					    </div>
              <div className="wrap-input100full">
						    <input className={`input100 ${password!==''?'has-val':''}`} value={password} onChange={(evt)=>{this.handleChange("password", evt.target.value)}} type="password" />
						    <span className="label-input100">Contraseña</span>
					    </div>
					    <div className="container-login100-form-btn">
						    <button className="login100-form-btn">
							    Iniciar Sesión
						    </button>
					    </div>
				    </form>
			    </div>
		    </div>
	    </div>
    );
  }
}

export default AdminLoginPage;