import Answer from './Answer';

export default class Question {
    constructor(id, quiz, questionGroup, index, value){
        this.id = id;
        this.quiz = quiz;
        this.questionGroup = questionGroup;
        this.index = index;
        this.value = value;
        this.answers = [];
    }

    addAnswer(id, index, value){
        this.answers.push(new Answer(id, this, index, value));
    }
}