import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BarChartIcon from '@material-ui/icons/BarChart';
import Modal from '@material-ui/core/Modal';
import Topbar from './components/Topbar';

import QuizResult from './components/QuizResult';
import eip from '../images/eip.png';
import backgroundShape from '../images/shape.svg';

const numeral = require('numeral');
numeral.defaultFormat('0,000');

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  loadingState: {
    opacity: 0.05
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152,
    height: 36
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing(2),
  },
  loanAvatar: {
    display: 'inline-block',
    verticalAlign: 'center',
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  interestAvatar: {
    display: 'inline-block',
    verticalAlign: 'center',
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  mainBadge: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
});

class AdminPage extends Component {

  constructor(props){
    super(props);
    const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;
    fetch(`${baseURL}/api/quiz/list/${props.match.params.group}`, {
      method: "get",
      credentials: 'include',
      headers:new Headers({Authorization: "Bearer " + props.match.params.token })
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          rows: data
        });
      })
      .catch(error => console.log("error", error));
      this.state = {
        rows: [],
        quizResponse: null
      };
  }

  render() {
    //console.log("token", this.props.match.params.token);
    const { classes, match } = this.props;
    const { rows, quizResponse } = this.state;
    const currentPath = this.props.location.pathname
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container justify="center" >
            <Grid alignItems="center" justify="center" container className={classes.grid}>
              <Grid item xs={12}>
                <div className={classes.topBar}>
                  <div className={classes.block}>
                    <Typography variant="h6" gutterBottom>Pruebas para el grupo {match.params.group}</Typography>
                    <Typography variant="body1">
                      Listado de todas las pruebas tomadas hasta ahora
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <div>
                      <div className={classes.box}>
                        <Typography color='secondary' gutterBottom>
                          Listado de pruebas
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Nombre Completo</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Whatsapp</TableCell>
                                <TableCell align="right">Puntaje</TableCell>
                                <TableCell align="right">Ver resultados</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map(row => (
                                <TableRow key={row.id}>
                                  <TableCell component="th" scope="row">
                                    {row.firstName + " " + row.lastName + " " + row.motherMaidenName}
                                  </TableCell>
                                  <TableCell align="right">{row.email}</TableCell>
                                  <TableCell align="right">{row.phoneNumber}</TableCell>
                                  <TableCell align="right">{row.score}</TableCell>
                                  <TableCell align="right"><a href="#!" onClick={e=>{
                                      this.setState({
                                        quizResponse: row
                                      });
                                    }}><BarChartIcon /></a></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Modal
          open={quizResponse !== null}
          onClose={()=>{
            this.setState({quizResponse: null});
          }}
          onRendered={()=>{
            const quizResultModal = document.getElementById('quizResultModal');
            if (quizResultModal){
              quizResultModal.scrollIntoView({behavior: "smooth", block: "center"});
            }
          }}
          style={{
            width: "560px", 
            position: "absolute",
            top: "5%",
            left: "10%"
          }}>
            <>
          <div className="d-flex mt-4 pt-3">
            <img src={eip} style={{width: '100%'}} alt="logo" />
          </div>
          <div className="bgblack" style={{textAlign: 'center', padding: '2vh', color: 'white'}}>
            <h2>CERTIFICADO DE PARTICIPACIÓN</h2>EN<br/><h3>TEST DE INNOVACIÓN</h3>
          </div>
          <div className="bgwhite" style={{textAlign: 'left', padding: '2vh', border: '1px', borderColor: 'black'}}>
            <h5><b>Nombre:</b> {quizResponse ? quizResponse.firstName : ""} {quizResponse ? quizResponse.lastName : ""} {quizResponse ? quizResponse.motherMaidenName : ""}</h5>
            <h5><b>Fecha:</b> {quizResponse ? quizResponse.created_at.split(' ')[0].split('T')[0] : ''}</h5>
          </div>
          <QuizResult 
            firstName={quizResponse ? quizResponse.firstName : "" } 
            lastName={quizResponse ? quizResponse.lastName : "" } 
            motherMaidenName={quizResponse ? quizResponse.motherMaidenName : "" } 
            email={quizResponse ? quizResponse.email : "" } 
            answers={quizResponse ? quizResponse.answers.split(",") : [] } />
            </>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(AdminPage));