export default class QuizResponse {
    constructor(id, quiz, firstName, lastName, motherMaidenName, email, answers){
        this.id = id;
        this.quiz = quiz;
        this.firstName = firstName;
        this.lastName = lastName;
        this.motherMaidenName = motherMaidenName;
        this.email = email;
        this.answers = [];
        for (let i=0; i<answers.length; i++){
            this.answers.push({
                question: quiz.questions[i],
                value: parseFloat(answers[i])
            });
        }
    }

    isNumeric(value) {
        return !isNaN(value - parseFloat(value));
    }

    calculate(){
        let total = 0;
        let response = {groups: []};
        for (let i = 0; i<this.quiz.questionGroups.length; i++){
            const questionGroup = this.quiz.questionGroups[i];
            let questionGroupTotal = 0;
            for (let j = 0; j<this.answers.length; j++){
                const answer = this.answers[j];
                if (questionGroup.id === answer.question.questionGroup.id){
                    questionGroupTotal += answer.value;
                }
            }
            response.groups.push({
                id: questionGroup.id,
                name: questionGroup.name,
                average: questionGroupTotal / questionGroup.questions.length,
                total: questionGroupTotal
            });
            total += questionGroupTotal;
        }
        response.value = total;
        if (total < 144){
            response.category = "LOW"
        }else if(total < 216){
            response.category = "MEDIUM"
        }else if(total < 288){
            response.category = "HIGH"
        }else{
            response.category = "VERY_HIGH"
        }
        return response;
    }
}