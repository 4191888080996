import React from 'react';
import qs from 'querystring';
import eip from '../images/eip.png';

import QuizResult from './components/QuizResult';

function ResultPage(props) {
    const params = qs.parse(props.location.search.substring(1));
    return (
        <div className="limiter">
		    <div className="container-login100">
			    <div className="wrap-login100 p-b-160 p-t-50">
                    <div className="d-flex mt-4 pt-3">
                        <img src={eip} style={{width: '100%'}} alt="logo" />
                    </div>
                    <div className="bgblack" style={{textAlign: 'center', padding: '2vh', color: 'white'}}>
                        <h2>CERTIFICADO DE PARTICIPACIÓN</h2>EN<br/><h3>TEST DE INNOVACIÓN</h3>
                    </div>
                    <div className="bgwhite" style={{textAlign: 'left', padding: '2vh', border: '1px', borderColor: 'black'}}>
                        <h5><b>Nombre:</b> {params.firstName} {params.lastName} {params.motherMaidenName}</h5>
                        <h5><b>Fecha:</b> {new Date().toISOString().split(' ')[0].split('T')[0]}</h5>
                    </div>
                    <QuizResult firstName={params.firstName} lastName={params.lastName} motherMaidenName={params.motherMaidenName} email={params.email} answers={params.answers.split(",")} history={props.history} />
                </div>
            </div>
        </div>
    );
}

export default ResultPage;