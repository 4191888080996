import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'
import LoginPage from './screens/LoginPage';
import QuizPage from './screens/QuizPage';
import ResultPage from './screens/ResultPage';
import AdminLoginPage from './screens/AdminLoginPage';
import AdminPage from './screens/AdminPage';
import GroupsPage from './screens/GroupsPage';
import ResponsesPage from './screens/ResponsesPage';
import { blue, indigo } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[900]
    },
    primary: {
      main: indigo[700]
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});

function App() {
  // console.log("location", window.location.pathname.substring(1));
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <main>
          <Switch>
            <Route exact path="/" render={props => (<LoginPage {...props} group={1} />)} />
            <Route path="/group/:group" render={props => (<LoginPage {...props} />)} />
            <Route exact path="/quiz" render={props => (<QuizPage {...props} />)} />
            <Route exact path="/result" render={props => (<ResultPage {...props} />)} />
            <Route exact path="/login" render={props => (<AdminLoginPage {...props} />)} />
            <Route path="/admin/:token" render={props => (<AdminPage {...props} />)} />
            <Route path="/groups/:token" render={props => (<GroupsPage {...props} />)} />
            <Route path="/responses/:group/:token" render={props => (<ResponsesPage {...props} />)} />
          </Switch>
        </main>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;