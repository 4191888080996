import React from 'react';

import { useTranslation } from "react-i18next";

import ReactSpeedometer from "react-d3-speedometer";
import { Chart } from "react-google-charts";

import QuizInteractor from '../../domain/usecases/QuizInteractor';
import QuizResponse from '../../domain/entities/QuizResponse';

const options = {
    hAxis: { title: "Valor promedio", viewWindow: { min: 0, max: 5 } },
    vAxis: { viewWindow: { min: 0, max: 16 } },
    legend: "none",
    chartArea: {  width: "38%", height: "70%", right: 10, top: 10 }
};

function QuizResult(props) {
    const { t } = useTranslation();

    const quizInteractor = new QuizInteractor();
    const quiz = quizInteractor.getAvailableQuizzes()[0];
    const quizResponse = new QuizResponse(1, quiz, props.firstName, props.lastName, props.motherMaidenName, props.email, props.answers).calculate();

    const data = [
        ["Area", t('averageValue'), { role: "style" }]
    ];
    for (let i=0; i<quizResponse.groups.length; i++){
        data.push([t(quizResponse.groups[i].name), quizResponse.groups[i].average, ""]);
        quizResponse.category = t(quizResponse.category);
    }
    options.hAxis.title = t('averageValue');
    return [
        <div id='quizResultModal' className="p-4 bg-white">
            <h3 className="my-3">{t('questionnaireResult' )}</h3>
            <div className="d-flex mt-4 pt-3">
                <ReactSpeedometer
                    needleHeightRatio={0.7}
                    maxSegmentLabels={4}
                    segments={3}
                    maxValue={360}
                    minValue={72}
                    customSegmentStops={[
                        72,
                        144,
                        216,
                        288,
                        360
                    ]}
                    segmentColors={[
                        '#ff0000',
                        '#00b050',
                        '#feff00',
                        '#8db4e1'
                    ]}
                    value={parseInt(quizResponse.value,10)}
                    height={150}
                    width={250}
                />
                <div className="pl-4"><h4 dangerouslySetInnerHTML={{__html: t('coihResult', {quizResponse})}}></h4></div>
            </div>
            <div className="my-4">{t('avgResults')}</div>
            <Chart
                chartType="BarChart"
                data={data}
                width="100%"
                height="400px"
                options={options}
            />
        </div>,
        <div className="container-login100-form-btn no-print">
		    <button className="login100-form-btn" onClick={()=>{
                const {history} = props;
                history.push("/");
            }}>
			    {t('startAgain')}
			</button>
        </div>
    ];
}

export default QuizResult;