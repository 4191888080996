import Quiz from '../entities/Quiz';
import QuestionGroup from '../entities/QuestionGroup';

export default class QuizInteractor {

    constructor(quizRepository){
        this.quizRepository = quizRepository;
    }

    getAnswers(currentIndex){
        const answers = [];
        for (let i = 1; i <= 5; i++){
            answers.push({
                id: currentIndex + i,
                index: i,
                value: i
            });
        }
        return answers;
    }

    getAvailableQuizzes(){
        const quizzes = [],
            quiz = new Quiz(1, "COIH"), 
            questionGroup1 = new QuestionGroup(1, 'g01'),
            questionGroup2 = new QuestionGroup(2, 'g02'),
            questionGroup3 = new QuestionGroup(3, 'g03'),
            questionGroup4 = new QuestionGroup(4, 'g04'),
            questionGroup5 = new QuestionGroup(5, "g05"),
            questionGroup6 = new QuestionGroup(6, "g06"),
            questionGroup7 = new QuestionGroup(7, "g07"),
            questionGroup8 = new QuestionGroup(8, "g08"),
            questionGroup9 = new QuestionGroup(9, "g09"),
            questionGroup10 = new QuestionGroup(10, "g10"),
            questionGroup11 = new QuestionGroup(11, "g11"),
            questionGroup12 = new QuestionGroup(12, "g12"),
            questionGroup13 = new QuestionGroup(13, "g13"),
            questionGroup14 = new QuestionGroup(14, "g14"),
            questionGroup15 = new QuestionGroup(15, "g15"),
            questionGroup16 = new QuestionGroup(16, "g16");
        
        quiz.addQuestion(1, questionGroup1, 1, 
            "q01", 
            this.getAnswers(0*5));
        quiz.addQuestion(2, questionGroup1, 2, 
            "q02", 
            this.getAnswers(1*5));
        quiz.addQuestion(3, questionGroup1, 3, 
            "q03", 
            this.getAnswers(2*5));
        quiz.addQuestion(4, questionGroup1, 4, 
            "q04", 
            this.getAnswers(3*5));
        quiz.addQuestion(5, questionGroup2, 5, 
            "q05", 
            this.getAnswers(4*5));
        quiz.addQuestion(6, questionGroup2, 6, 
            "q06", 
            this.getAnswers(5*5));
        quiz.addQuestion(7, questionGroup2, 7, 
            "q07", 
            this.getAnswers(6*5));
        quiz.addQuestion(8, questionGroup2, 8, 
            "q08", 
            this.getAnswers(7*5));
        quiz.addQuestion(9, questionGroup3, 9, 
            "q09", 
            this.getAnswers(8*5));
        quiz.addQuestion(10, questionGroup3, 10, 
            "q10", 
            this.getAnswers(9*5));
        quiz.addQuestion(11, questionGroup3, 11, 
            "q11", 
            this.getAnswers(10*5));
        quiz.addQuestion(12, questionGroup3, 12, 
            "q12", 
            this.getAnswers(11*5));
        quiz.addQuestion(13, questionGroup3, 13, 
            "q13", 
            this.getAnswers(12*5));
        quiz.addQuestion(14, questionGroup3, 14, 
            "q14", 
            this.getAnswers(13*5));
        quiz.addQuestion(15, questionGroup3, 15, 
            "q15", 
            this.getAnswers(14*5));
        quiz.addQuestion(16, questionGroup3, 16, 
            "q16", 
            this.getAnswers(15*5));
        quiz.addQuestion(17, questionGroup4, 17, 
            "q17", 
            this.getAnswers(16*5));
        quiz.addQuestion(18, questionGroup4, 18, 
            "q18", 
            this.getAnswers(17*5));
        quiz.addQuestion(19, questionGroup4, 19, 
            "q19", 
            this.getAnswers(18*5));
        quiz.addQuestion(20, questionGroup5, 20, 
            "q20", 
            this.getAnswers(19*5));
        quiz.addQuestion(21, questionGroup5, 21, 
            "q21", 
            this.getAnswers(20*5));
        quiz.addQuestion(22, questionGroup5, 22, 
            "q22", 
            this.getAnswers(21*5));
        quiz.addQuestion(23, questionGroup6, 23, 
            "q23", 
            this.getAnswers(22*5));
        quiz.addQuestion(24, questionGroup6, 24, 
            "q24", 
            this.getAnswers(23*5));
        quiz.addQuestion(25, questionGroup6, 25, 
            "q25", 
            this.getAnswers(24*5));
        quiz.addQuestion(26, questionGroup6, 26, 
            "q26", 
            this.getAnswers(25*5));
        quiz.addQuestion(27, questionGroup6, 27, 
            "q27", 
            this.getAnswers(26*5));
        quiz.addQuestion(28, questionGroup7, 28, 
            "q28", 
            this.getAnswers(27*5));
        quiz.addQuestion(29, questionGroup7, 29, 
            "q29", 
            this.getAnswers(28*5));
        quiz.addQuestion(30, questionGroup7, 30, 
            "q30", 
            this.getAnswers(29*5));
        quiz.addQuestion(31, questionGroup8, 31, 
            "q31", 
            this.getAnswers(30*5));
        quiz.addQuestion(32, questionGroup8, 32, 
            "q32", 
            this.getAnswers(31*5));
        quiz.addQuestion(33, questionGroup8, 33, 
            "q33", 
             this.getAnswers(32*5));
        quiz.addQuestion(34, questionGroup8, 34, 
            "q34", 
            this.getAnswers(33*5));
        quiz.addQuestion(35, questionGroup8, 35, 
            "q35", 
            this.getAnswers(34*5));
        quiz.addQuestion(36, questionGroup9, 36, 
            "q36", 
            this.getAnswers(35*5));
        quiz.addQuestion(37, questionGroup9, 37, 
            "q37", 
            this.getAnswers(36*5));
        quiz.addQuestion(38, questionGroup9, 38, 
            "q38", 
            this.getAnswers(37*5));
        quiz.addQuestion(39, questionGroup10, 39, 
            "q39", 
            this.getAnswers(38*5));
        quiz.addQuestion(40, questionGroup10, 40, 
            "q40", 
            this.getAnswers(39*5));
        quiz.addQuestion(41, questionGroup10, 41, 
            "q41", 
            this.getAnswers(40*5));
        quiz.addQuestion(42, questionGroup10, 42, 
            "q42", 
            this.getAnswers(41*5));
        quiz.addQuestion(43, questionGroup10, 43, 
            "q43", 
            this.getAnswers(42*5));
        quiz.addQuestion(44, questionGroup10, 44, 
            "q44", 
            this.getAnswers(43*5));
        quiz.addQuestion(45, questionGroup10, 45, 
            "q45", 
            this.getAnswers(44*5));
        quiz.addQuestion(46, questionGroup11, 46, 
            "q46", 
            this.getAnswers(45*5));
        quiz.addQuestion(47, questionGroup11, 47, 
            "q47", 
            this.getAnswers(46*5));
        quiz.addQuestion(48, questionGroup11, 48, 
            "q48", 
            this.getAnswers(47*5));
        quiz.addQuestion(49, questionGroup11, 49, 
            "q49", 
            this.getAnswers(48*5));
        quiz.addQuestion(50, questionGroup11, 50, 
            "q50", 
            this.getAnswers(49*5));
        quiz.addQuestion(51, questionGroup11, 51, 
            "q51", 
            this.getAnswers(50*5));
        quiz.addQuestion(52, questionGroup12, 52, 
            "q52", 
            this.getAnswers(51*5));
        quiz.addQuestion(53, questionGroup12, 53, 
            "q53", 
            this.getAnswers(52*5));
        quiz.addQuestion(54, questionGroup12, 54, 
            "q54", 
            this.getAnswers(53*5));
        quiz.addQuestion(55, questionGroup12, 55, 
            "q55", 
            this.getAnswers(54*5));
        quiz.addQuestion(56, questionGroup12, 56, 
            "q56", 
            this.getAnswers(55*5));
        quiz.addQuestion(57, questionGroup12, 57, 
            "q57", 
            this.getAnswers(56*5));
        quiz.addQuestion(58, questionGroup12, 58, 
            "q58", 
            this.getAnswers(57*5));
        quiz.addQuestion(59, questionGroup12, 59, 
            "q59", 
            this.getAnswers(58*5));
        quiz.addQuestion(60, questionGroup12, 60, 
            "q60", 
            this.getAnswers(59*5));
        quiz.addQuestion(61, questionGroup13, 61, 
            "q61", 
            this.getAnswers(60*5));
        quiz.addQuestion(62, questionGroup13, 62, 
            "q62", 
            this.getAnswers(61*5));
        quiz.addQuestion(63, questionGroup13, 63, 
            "q63", 
            this.getAnswers(62*5));
        quiz.addQuestion(64, questionGroup14, 64, 
            "q64", 
            this.getAnswers(63*5));
        quiz.addQuestion(65, questionGroup14, 65, 
            "q65", 
            this.getAnswers(64*5));
        quiz.addQuestion(66, questionGroup14, 66, 
            "q66", 
            this.getAnswers(65*5));
        quiz.addQuestion(67, questionGroup15, 67, 
            "q67", 
            this.getAnswers(66*5));
        quiz.addQuestion(68, questionGroup15, 68, 
            "q68", 
            this.getAnswers(67*5));
        quiz.addQuestion(69, questionGroup15, 69, 
            "q69", 
            this.getAnswers(68*5));
        quiz.addQuestion(70, questionGroup16, 70, 
            "q70", 
            this.getAnswers(69*5));
        quiz.addQuestion(71, questionGroup16, 71, 
            "q71", 
            this.getAnswers(70*5));
        quiz.addQuestion(72, questionGroup16, 72, 
            "q72", 
            this.getAnswers(71*5));
        quizzes.push(quiz);
        return quizzes;
    }

}